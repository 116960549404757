<template>

  <section
  style="
  display: flex;
  flex-direction: column;
  width: 128px;
  height: 128px;
  padding: 8px;
  background-color: #FFF;
  border: 1px solid #DDD;">
          <section
          v-if="isLoading"
          style="
          font-size: 8px;
          padding: 3px;
          color: #666666;
          text-align: center;
          ">
              <div><b>読み込み中</b></div>
          </section>
          <section
          v-else-if="reviewCount"
          style="
          font-size: 8px;
          padding: 3px;
          color: #666666;
          text-align: center;
          ">
              <div><b>{{ reviewCount }}件</b></div>
              <div><b>のクチコミを評価</b></div>
          </section>
          <section
          v-else
          style="
          font-size: 8px;
          padding: 3px;
          color: #666666;
          text-align: center
          ">
              <div><b>分析データ</b></div>
              <div><b>が作成されていません</b></div>
          </section>

          <section
          v-if="isLoading"
          style="
          font-size: 8px;
          padding: 3px;
          color: #999999;
          text-align: center
          ">
              <div
              style="
              width: 25px;
              height: 25px;
              margin: 10px auto;
              padding-top: 5px;
              padding-bottom: 5px;
              box-sizing: border-box;
              border-radius: 50%;
              border: 2px solid #ccc;
              border-top-color: #000;
              animation: spinner .6s linear infinite;
              transform: rotate(360deg);
              "></div>
          </section>
          <section
          style="
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          padding:3px;
          font-size:8px;
          color: orange;
          font-weight: bold;
          ">
              <div align="center">
                  <span style="font-size: 20px">★ {{ reputation ? reputation : '--' }}</span>
                  /
                  10
              </div>
          </section>

          <section
          style="
          padding-left: 1px;
          padding-right: 1px;
          padding-top: 5px;
          padding-bottom: 3px;
          font-size: 10px;
          ">
                  <div align="center">
                      <a
                      :href="`${siteUrl}/${hotelId}`"
                      :disabled="!reviewCount"
                      target="_black"
                      style="
                      text-decoration: none;
                      background: #444444;
                      color: #ffffff;
                      padding: 0.25em 1em;
                      border-radius: 0.75em;
                      ">
                          クチコミを見る
                      </a>
                  </div>
          </section>

          <footer
          class="footer"
          style="
          margin-top: 4px;
          font-size: 8px;
          color: #AAA;
          text-align: center;
          ">
              <div>Powered by</div>
              <div> RepChecker</div>
          </footer>

  </section>

</template>

<script>
import axios from 'axios'
import $ from 'jquery'

export default {
  
  async created(){
    await this.getData()
  },
  
  props: {
    hotelId: String,
  },
  data: () => ({
      typeCheck: 'link',
      isLoading: true,
      data: {
        hotelId: null,//required
        hotelName: null,
        reviewCount: null,
        reputation: null,
        reviews: [],
        config: {
          onlySelectedReviews: false,//required
          onlySelectedOtas: false,//required
          otaNames: [],//required
        },
        createdAt: null,
        updatedAt: null,
      },
  }),
  computed: {
    siteUrl(){
      return 'https://widget.repchecker.jp'
    },
    reviewCount(){
      return this.data.reviewCount
    },
    reputation(){
      return this.data.reputation
    },
  },
  methods: {
    async getData(){
      this.isLoading = true
      try {
        const baseUrl = "https://cbjzmu0s7i.execute-api.ap-northeast-1.amazonaws.com/dev/api/v1/widget"
        const url = `${baseUrl}/${this.hotelId}`
        const { data } = await axios.get(url)
        this.data = data
      }
      catch (e) {
        console.error(e)
        this.isLoading = false
      }
      finally {
        this.isLoading = false
      }
    },
  },
}
</script>
