<style lang="scss" scoped>
</style>

<template>
    <section>
        <a v-bind:href="'/'+this.hotelId" target="_black">クチコミを見る</a>
    </section>
</template>

<script>
export default {
    data: () => ({
    }),
    computed: {
      reviewCount(){
        return this.data.reviewCount
      },
      reputation(){
        return this.data.reputation
      },
  },
    props: {
            data: {},
            hotelId: {},
    },
}
</script>
    