
    <template v-if="typeCheck == 'link'">
        <link-style 
            :hotelId="hotelId"
        >
        </link-style>
    </template>
    <template v-else>
        <square 
            :hotelId="hotelId"
        >
        </square>
    </template>
</iframe>

<script>
import axios from 'axios'
import $ from 'jquery'
import Square from './type/Square'
import LinkStyle from './type/LinkStyle'

//const apiUrl = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode=7830060'
export default {
    components: {
        Square,
        LinkStyle
    },
  async created(){
    this.typeCheck = $('#rc-review-widget').attr('data-type')
    this.hotelId = $('#rc-review-widget').attr('data-hotel')
    
  },
  data: () => ({
    typeCheck: 'link',
    isLoading: true,
    hotelId: null,
  }),

}
</script>